/**
 * Labstep
 *
 * @module components/EntityView/Form/ProtocolCollection
 * @desc Select ProtocolCondition to initialize report
 */

import EntitySearchMulti from 'labstep-web/components/Entity/Search/Multi';
import EntityViewFormCreateListItem from 'labstep-web/components/EntityView/Form/Create/ListItem';
import { ICONS } from 'labstep-web/constants/icons';
import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import { ReportingColDef } from 'labstep-web/grid/Report/coldefs/types';
import { GridReportService } from 'labstep-web/grid/Report/services/grid-report.service';
import { EntityView } from 'labstep-web/models/entity-view.model';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { navigation } from 'labstep-web/services/navigation';
import {
  PostFilterComparison,
  PostFilterOperator,
} from 'labstep-web/services/query-parameter.service';
import React from 'react';
import { withRouter } from 'react-router';
import { EntityViewFormCreateProtocolCollectionProps } from './types';

export const EntityViewFormCreateProtocolCollection: React.FC<
  EntityViewFormCreateProtocolCollectionProps
> = ({ group, folderId, onSuccess, history }) => (
  <EntityCreateContainer entityName={EntityView.entityName}>
    {({ create }) => (
      <EntitySearchMulti
        lists={[
          {
            key: ProtocolCollection.entityName,
            entityName: ProtocolCollection.entityName,
            searchField: 'name_like',
            params: {
              count: 5,
              group_id: group.guid,
              has_last_version: true,
              serializerGroups:
                'protocol_collection_reporting,paginator',
            },
            header: '',
            listItem: (entity: object) => {
              return (
                <EntityViewFormCreateListItem
                  icon={ICONS.protocol_collection.primary}
                  text={(entity as ProtocolCollection).name || ''}
                  count={
                    (entity as ProtocolCollection)
                      .last_version_all_experiment_count
                  }
                  onClick={() => {
                    create(
                      {
                        entity_name: ProtocolCondition.entityName,
                        context: 'reporting',
                        group_id: group.guid,
                        folder_id: folderId,
                        name: `Comparing runs of ${
                          (entity as ProtocolCollection).name
                        }`,
                        column_definition_ids:
                          GridReportService.getProtocolColDefs(
                            (entity as ProtocolCollection)
                              .last_version!,
                          ).map(
                            (colDef: ReportingColDef) => colDef.colId,
                          ),
                        parameters: {
                          filter: [
                            {
                              type: PostFilterOperator.and,
                              predicates: [
                                {
                                  type: PostFilterOperator.and,
                                  path: 'experiment.protocol.collection',
                                  predicates: [
                                    {
                                      attribute: 'guid',
                                      comparison:
                                        PostFilterComparison.eq,
                                      value: (
                                        entity as ProtocolCollection
                                      ).guid,
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              type: PostFilterOperator.and,
                              predicates: [],
                            },
                          ],
                        },
                      },
                      {
                        onSuccess: ({ response }) => {
                          onSuccess();
                          history.push(
                            navigation.get('entity_view_show', {
                              id: group.id,
                              entityViewId: response.result,
                            }),
                          );
                        },
                      },
                    );
                  }}
                />
              );
            },
          },
        ]}
      />
    )}
  </EntityCreateContainer>
);

export default withRouter(EntityViewFormCreateProtocolCollection);
