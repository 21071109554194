/**
 * Labstep
 *
 * @module screens/Device/Index
 * @desc Device Index
 */

import DeviceCalendar from 'labstep-web/components/Device/Calendar';
import DeviceEmptyState from 'labstep-web/components/Device/Card/EmptyState';
import DeviceListOrTable from 'labstep-web/components/Device/ListOrTable';
import EntityActionCreateMenu from 'labstep-web/components/Entity/Action/Create/Menu';
import { EntitySearchContainerChildrenProps } from 'labstep-web/components/Entity/Search/Children/types';
import EntityImportModal from 'labstep-web/components/EntityImport/Modal/Trigger';
import { useParamsContext } from 'labstep-web/contexts/params/hook';
import { Device } from 'labstep-web/models/device.model';
import MasterIndex from 'labstep-web/screens/Master/Index';
import { selectEntity } from 'labstep-web/state/selectors/entity';
import store from 'labstep-web/state/store';
import React, { useMemo } from 'react';
import DeviceIndexBreadcrumb from './Breadcrumb';
import DeviceIndexLeftPane from './LeftPane';
import { bulkActions, filters, sortOptions } from './constants';
import {
  IDeviceIndexListOrTableProps,
  IDeviceIndexProps,
} from './types';

export const DeviceIndexListOrTable: React.FC<
  IDeviceIndexListOrTableProps
> = ({ entities, ...rest }) => {
  const { searchParams, setParams } = useParamsContext();
  return (
    <DeviceListOrTable
      devices={entities}
      templateId={searchParams.template_id as number}
      {...rest}
    />
  );
};

export const DeviceIndex: React.FC<IDeviceIndexProps> = ({
  group,
}) => {
  const [activeView, setActiveView] = React.useState<
    'grid' | 'calendar'
  >('grid');
  const params = useMemo(() => ({ group_id: group.id }), [group.id]);
  const action = ({ searchParams }) => (
    <EntityActionCreateMenu
      entityName={Device.entityName}
      extraOptions={
        <EntityImportModal
          targetEntityName={Device.entityName}
          templateId={searchParams!.template_id}
        />
      }
      templateId={searchParams!.template_id}
    />
  );
  const breadcrumb = (
    <DeviceIndexBreadcrumb
      setActiveView={setActiveView}
      activeView={activeView}
    />
  );
  const leftPane = useMemo(() => <DeviceIndexLeftPane />, []);
  const noResultsMessage = ({ searchParams }) => (
    <DeviceEmptyState action={action({ searchParams })} />
  );

  const entityView = (searchParams) => {
    const id = searchParams.template_id;
    // TODO: this is a hack to get the guid of the template
    // Will remove once we migrate to guid
    const guid = id
      ? selectEntity(store.getState(), Device.entityName, id)?.guid
      : undefined;
    return {
      context: 'device_index',
      entityName: Device.entityName,
      parameters: guid ? { device_template_guid: guid } : undefined,
    };
  };

  return (
    <MasterIndex
      action={action}
      bulkActions={bulkActions}
      breadcrumb={breadcrumb}
      entityName={Device.entityName}
      filters={filters}
      leftPane={leftPane}
      noResultsMessage={noResultsMessage}
      params={params}
      tableFormat={false}
      sortOptions={sortOptions}
      useAllPages
      entityView={entityView}
    >
      {({
        entities,
        readNextPage,
        status,
      }: EntitySearchContainerChildrenProps) =>
        activeView === 'grid' ? (
          <DeviceIndexListOrTable
            entities={entities}
            loadMore={readNextPage}
            status={status}
          />
        ) : (
          <DeviceCalendar devices={entities} />
        )
      }
    </MasterIndex>
  );
};

export default DeviceIndex;
