/**
 * Labstep
 *
 * @module components/EntitySnapshot/Modal
 * @desc List of snapshots based on selected log
 */

import EntitySnapshotList from 'labstep-web/components/EntitySnapshot/List';
import EntitySnapshotListItemEntity from 'labstep-web/components/EntitySnapshot/ListItemEntity';
import EntitySnapshotLogLegacyList from 'labstep-web/components/EntitySnapshot/LogLegacyList';
import LogList from 'labstep-web/components/Log/List';
import { VerticalDivider } from 'labstep-web/core/Divider';
import Header from 'labstep-web/core/Header';
import Icon from 'labstep-web/core/Icon';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { EntitySnapshot } from 'labstep-web/models/entity-snapshot.model';
import React from 'react';
import { v4 } from 'uuid';
import styles from './styles.module.scss';
import { EntitySnapshotModalProps } from './types';

export const EntitySnapshotModal: React.FC<
  EntitySnapshotModalProps
> = ({ log }) => {
  const [selectedEntitySnapshot, setSelectedEntitySnapshot] =
    React.useState<EntitySnapshot | null>(
      new EntitySnapshot({
        id: v4(),
        created_at: log.ended_at ? log.ended_at : log.created_at,
        object_id: log.object_id,
        object_class: EntitySnapshot.entityNameToObjectClass(
          log.objectClassToEntityName,
        ),
      }),
    );
  return (
    <>
      <ModalDefault
        header="Time Machine"
        size="fullscreen"
        content={() => (
          <div className={styles.container}>
            <div>
              {selectedEntitySnapshot && (
                <EntitySnapshotListItemEntity
                  key={selectedEntitySnapshot.id}
                  entitySnapshot={selectedEntitySnapshot}
                />
              )}
            </div>
            <VerticalDivider stretch />
            <div>
              <Header size="tiny">Versions</Header>
              {log.object_id && log.object_class && (
                <LogList
                  objectId={log.object_id}
                  objectClass={log.object_class}
                  selectedEntitySnapshot={selectedEntitySnapshot}
                  setSelectedEntitySnapshot={
                    setSelectedEntitySnapshot
                  }
                />
              )}
              <EntitySnapshotLogLegacyList
                log={log}
                setSelectedEntitySnapshot={setSelectedEntitySnapshot}
              />
            </div>
          </div>
        )}
        viewComponent={({ toggleModal }) => {
          if (
            log.object_class === 'Experiment' ||
            log.object_class === 'Protocol' ||
            log.object_class === 'ProtocolTable'
          ) {
            return (
              <Icon name="external alternate" onClick={toggleModal} />
            );
          }
          return null;
        }}
      />
    </>
  );
};

export default EntitySnapshotModal;
