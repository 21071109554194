import Checkbox from 'labstep-web/core/Checkbox';
import Flex from 'labstep-web/core/Flex';
import FloatingSearchSelect from 'labstep-web/core/Select/FloatingSearch';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import { EntityRelation } from 'labstep-web/models/entity-relation.model';
import { Resource } from 'labstep-web/models/resource.model';
import { generateNewDateString } from 'labstep-web/services/date.service';
import { updateEntity } from 'labstep-web/state/actions/entity';
import store from 'labstep-web/state/store';
import { EntityRelationSearchSelectProps } from './types';

export const EntityRelationSearchSelect: React.FC<
  EntityRelationSearchSelectProps
> = ({
  fieldDefinition,
  sourceEntity,
  createEntityRelation,
  stopEdit,
}) => {
  const { activeGroup } = useActiveGroup();

  return (
    <FloatingSearchSelect
      actionComponentProps={{
        type: 'children',
        children: () => <></>,
      }}
      entityName={sourceEntity.entityName}
      placeholder={`Search ${sourceEntity.entityName}...`}
      keepMenuOpen
      autoFocus
      open={true}
      params={{
        ...fieldDefinition.entity_relation_target_entity_filter,
        group_id: activeGroup!.id,
        count: 20,
      }}
      setOptions={(options: Resource[]) => {
        const linkedResources = (sourceEntity as Resource)
          .getEntityRelationsByFieldDefinition(fieldDefinition)
          .map((entityRelation) => {
            const resource = (
              entityRelation.source?.guid === sourceEntity.guid
                ? entityRelation.target
                : entityRelation.source
            ) as Resource;
            return {
              ...resource,
              isLinked: true,
              relationGuid: entityRelation.guid,
            };
          });
        const linkedResourcesGuids = linkedResources.map(
          (resource) => resource.guid,
        );

        const otherResources = options
          .filter(
            (option) => !linkedResourcesGuids.includes(option.guid),
          )
          .map((option: Resource) => ({
            ...option,
            name: option.displayName,
          }));
        return [
          {
            label: 'Linked Resource',
            options: linkedResources,
          },
          {
            label: 'Link Other Resource',
            options: otherResources,
          },
        ];
      }}
      formatOptionLabel={(option: any) => {
        console.log(option);

        return (
          <Flex
            spacing="gap"
            data-cy={`components/EntityRelation/SearchSelect:Checkbox-${option.isLinked ? 'checked' : 'unchecked'}`}
          >
            <Checkbox checked={option.isLinked} />
            <span>{option.name}</span>
          </Flex>
        );
      }}
      onChange={(option) => {
        if (option.relationGuid) {
          store.dispatch(
            updateEntity(
              EntityRelation.entityName,
              option.relationGuid,
              { deleted_at: generateNewDateString() },
              {
                onSuccess: () => {
                  stopEdit();
                },
              },
            ),
          );
        } else {
          createEntityRelation(
            {
              [`source_${fieldDefinition!.entity_relation_target_entity_class}_id`]:
                sourceEntity.guid,
              [`target_${fieldDefinition!.entity_relation_target_entity_class}_id`]:
                option.guid,
              field_definition_id: fieldDefinition.id,
            },
            {
              onSuccess: () => {
                stopEdit();
              },
            },
          );
        }
      }}
    />
  );
};
