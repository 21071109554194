/**
 * Labstep
 *
 * @module components/Filter
 * @desc Main Filter component
 */

import SortOptions from 'labstep-web/components/Entity/Table/SortOptions';
import FilterMenu from 'labstep-web/components/Filter/Menu';
import SearchBarFilter from 'labstep-web/components/Filter/Search';
import LayoutContainerActionButtons from 'labstep-web/components/Layout/Container/ActionButtons';
import { ICONS } from 'labstep-web/constants/icons';
import Button from 'labstep-web/core/Button';
import Icon from 'labstep-web/core/Icon';
import Mobile from 'labstep-web/core/MediaQuery/Mobile';
import EntityDataGridActionSaveView from 'labstep-web/grid/Index/components/SaveView';
import EntityDataGridActionShowSideBar from 'labstep-web/grid/Index/components/ShowSideBar';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import FilterActiveWithSort from './Active/WithSort';
import styles from './styles.module.scss';
import { FilterProps } from './types';

export const Filter: React.FC<FilterProps> = ({
  action,
  entityName,
  isMobile,
  sortOptions,
  defaultSortOptions,
  searchBarProps,
  isTemplate,
  filters = [],
  searchKey = 'search_query',
}) => {
  const sortButton = sortOptions && (
    <SortOptions
      sortOptions={sortOptions}
      trigger={
        <Button className={styles.searchBarAction}>
          <Icon name="sort amount up" />
          Sort
        </Button>
      }
      showOrderAction={false}
      defaultSortOptions={defaultSortOptions}
    />
  );

  const filterButton = filters.length > 0 && (
    <FilterMenu
      trigger={
        <Button className={styles.searchBarAction}>
          <Icon name={ICONS.filter.primary} />
          Filter
        </Button>
      }
      entityName={entityName}
      filters={filters}
      clearAll={() => {}}
    />
  );

  return (
    <div data-testid="filter">
      <div className={styles.searchBarContainer}>
        <div
          data-testid="search-bar"
          className={styles.searchBar}
          data-cy="components/Filter:searchBar"
        >
          <SearchBarFilter
            searchBarProps={{
              label: !isMobile && (
                <div className={styles.searchBarActionContainer}>
                  {filterButton}
                  {sortButton}

                  <EntityDataGridActionShowSideBar
                    className={styles.searchBarAction}
                  />
                  <EntityDataGridActionSaveView />
                </div>
              ),
              placeholder: `Search for ${getHumanReadableEntityName(
                entityName,
                true,
                false,
                isTemplate,
              )}`,
              ...searchBarProps,
            }}
            searchKey={searchKey}
          />
          {isMobile ? (
            <LayoutContainerActionButtons>
              {action}
              {filterButton}
              {sortButton}
            </LayoutContainerActionButtons>
          ) : (
            action
          )}
        </div>
      </div>
      <FilterActiveWithSort
        sortOptions={sortOptions}
        defaultSortOptions={defaultSortOptions}
      />
    </div>
  );
};

const FilterContainer: React.FC<FilterProps> = (props) => (
  <Mobile>
    {({ match }): JSX.Element => (
      <Filter isMobile={match} {...props} />
    )}
  </Mobile>
);

export default FilterContainer;
