/**
 * Labstep
 *
 * @module state/epics/sf-api
 * @desc Redux epic for the symfony API
 */

import { Action } from 'labstep-web/models/action.model';
import { authenticationService } from 'labstep-web/services/authentication.service';
import { getRequestObs } from 'labstep-web/services/sf-api.service';
import { selectClientUuid } from 'labstep-web/state/new/client';
import { LabstepReduxState } from 'labstep-web/state/types';
import { StateObservable } from 'redux-observable';
import { Observable, concat, of } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';

interface IError {
  status: number;
  data: {
    message: string;
  };
}

const isMaintenanceError = (error: IError): boolean =>
  error &&
  error.status === 500 &&
  error.data &&
  error.data.message === 'Migration';

/**
 * Sends an action to disable authenticated user if account is locked
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onMaintenanceActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type.includes('FAIL') &&
        isMaintenanceError(action.error),
    ),
    map(() => ({
      type: 'SET_MAINTENANCE_ENABLED',
    })),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_SET_MAINTENANCE_ENABLED',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Send request
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state
 * @return {Observable<Action>}
 */
export const onRequestActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type.startsWith('REQUEST') &&
        !action.type.includes('_RAW_OUTPUT'),
    ),
    mergeMap((action) => {
      const clientUuid = selectClientUuid(state$.value);
      console.log('clientUuid', clientUuid);
      const { activeGroupId } = state$.value;

      if (action?.meta?.url?.includes('public-api')) {
        return getRequestObs(action, clientUuid, activeGroupId);
      }

      return authenticationService
        .getAuthentication(action, state$)
        .pipe(
          mergeMap((actionOrAuthentication) => {
            if (
              actionOrAuthentication.type.endsWith('REFRESH_TOKEN')
            ) {
              return of(actionOrAuthentication);
            }

            if (actionOrAuthentication.meta?.authentication?.apikey) {
              return getRequestObs(
                {
                  ...action,
                  meta: {
                    ...action.meta,
                    headers: {
                      ...action.meta.headers,
                      apikey:
                        actionOrAuthentication.meta?.authentication
                          ?.apikey,
                    },
                  },
                },
                clientUuid,
                activeGroupId,
              );
            }
            if (actionOrAuthentication.meta?.authentication?.jwt) {
              actionOrAuthentication.meta.headers = {
                ...actionOrAuthentication.meta.headers,
                Authorization: `Bearer ${actionOrAuthentication.meta.authentication.jwt}`,
              };
            }

            if (
              !actionOrAuthentication.meta?.url?.includes(
                'public-api',
              ) &&
              !actionOrAuthentication.meta?.headers?.apikey &&
              !actionOrAuthentication.meta?.headers?.Authorization
            ) {
              if (actionOrAuthentication.type === 'LOGOUT_SUCCESS') {
                return of(actionOrAuthentication);
              }
              return of({
                type: 'SKIP_REQUEST_AUTH_DATA_MISSING',
                meta: {
                  actionOrAuthentication,
                },
              });
            }

            return getRequestObs(
              actionOrAuthentication,
              clientUuid,
              activeGroupId,
            );
          }),
        );
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'EPIC_FAIL_SF_API_REQUEST', payload: err }),
        source$,
      ),
    ),
  );

/**
 * Call onSuccess
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onSuccessActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type.includes('SUCCESS') &&
        !action.type.includes('_RAW_OUTPUT'),
    ),
    tap((action: Action) => {
      if (action.meta && action.meta.onSuccess) {
        action.meta.onSuccess({
          response: action.payload,
          meta: action.meta,
        });
      }
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'EPIC_FAIL_SF_API_SUCCESS', payload: err }),
        source$,
      ),
    ),
  );

/**
 * Call onFail
 * Track error on bugsnag if available
 * @see https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onFailActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type.startsWith('FAIL')),
    tap((action: Action) => {
      if (action.meta && action.meta.onFail) {
        action.meta.onFail({ error: action.error });
      }
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({ type: 'EPIC_FAIL_SF_API_FAIL', payload: err }),
        source$,
      ),
    ),
  );
