/**
 * Labstep
 *
 * @module screens/Device/Index/Breadcrumb
 * @desc Device Index Breadcrumb
 */

import DevicePreview from 'labstep-web/components/Device/Preview';
import EntityTemplateBreadcrumbIndex from 'labstep-web/components/Entity/Template/Breadcrumb/Index';
import EntityImportActionPending from 'labstep-web/components/EntityImport/Action/Pending';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import { Device } from 'labstep-web/models/device.model';
import { set } from 'lodash';
import React from 'react';
import { Menu, MenuItem } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { IDeviceIndexBreadcrumbProps } from './types';

export const DeviceIndexBreadcrumb: React.FC<
  IDeviceIndexBreadcrumbProps
> = ({ setActiveView, activeView }) => (
  <>
    <Flex vAlignContent="center" spacing="gap" shrink={false}>
      <Flex vAlignContent="center" spacing="gap">
        <Menu size="mini" compact>
          <MenuItem
            onClick={() => setActiveView('grid')}
            link
            className={activeView === 'grid' ? styles.active : ''}
          >
            <Icon name="table" /> Grid View
          </MenuItem>
          <MenuItem
            onClick={() => setActiveView('calendar')}
            link
            className={activeView === 'calendar' ? styles.active : ''}
          >
            <Icon name="calendar" />
            Booking Overview
          </MenuItem>
        </Menu>
      </Flex>
      <Flex>
        <EntityTemplateBreadcrumbIndex
          entityName={Device.entityName}
          entityPreview={({
            entity,
            ...rest
          }): React.ReactElement => (
            <DevicePreview device={entity as Device} {...rest} />
          )}
        />
      </Flex>
    </Flex>
    <EntityImportActionPending targetEntityName={Device.entityName} />
  </>
);

export default DeviceIndexBreadcrumb;
