/**
 * Labstep
 *
 * @module models/experiment
 * @desc Typescript export class for Experiment
 */

import { Type } from 'class-transformer';
import { IPermissions, PermissionActions } from 'labstep-web/models';
import { Entity } from 'labstep-web/models/entity.model';
import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { File as ModelFile } from 'labstep-web/models/file.model';
import { Log } from 'labstep-web/models/log.model';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataThread } from 'labstep-web/models/metadata-thread.model';
import { Molecule } from 'labstep-web/models/molecule.model';
import { PermaLink } from 'labstep-web/models/perma-link.model';
import { ProtocolConditionVariableType } from 'labstep-web/models/protocol-condition.model';
import { ProtocolDevice } from 'labstep-web/models/protocol-device.model';
import { ProtocolStep } from 'labstep-web/models/protocol-step.model';
import { ProtocolTable } from 'labstep-web/models/protocol-table.model';
import { ProtocolTimer } from 'labstep-web/models/protocol-timer.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import { Thread } from 'labstep-web/models/thread.model';
import { User } from 'labstep-web/models/user.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { isEqual } from 'lodash';

interface CommentDecoration {
  id: number;
  text: string;
  from: number;
  to: number;
}

type ICondition = (e: ProtocolValue) => boolean;

export interface Decorations {
  comments: CommentDecoration[];
}

export class Experiment extends Entity {
  public static readonly entityName = 'experiment';

  public get entityName(): typeof Experiment.entityName {
    return Experiment.entityName;
  }

  public constructor(data: Partial<Experiment> = {}) {
    super();
    Object.assign(this, data);
  }

  public id!: number;

  public started_at!: string;

  public ended_at!: string;

  public permissions!: IPermissions;

  public description!: string;

  public metadata_count!: number;

  public protocol_table_count!: number;

  public protocol_value_count!: number;

  public protocol_value_input_count!: number;

  public protocol_value_output_count!: number;

  public protocol_timer_count!: number;

  public protocol_step_count!: number;

  public protocol_step_ended_count!: number;

  public protocol_device_count!: number;

  public position!: number;

  public is_root!: boolean;

  public is_use_latest_protocol!: boolean;

  public locked_at?: string;

  public protocol_collection_id?: number;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public state: any;

  public name!: string;

  public experiment_workflow_is_template!: boolean;

  public decorations!: Decorations;

  public allowed_actions_lock!: PermissionActions[];

  public protocol_condition_variables!: ProtocolConditionVariableType[];

  public n_protocol_conditions!: number;

  @Type(() => Log)
  public update_state_log?: Log;

  @Type(() => Log)
  public locked_log?: Log;

  @Type(() => User)
  public author!: User;

  @Type(() => Protocol)
  public protocol!: Protocol;

  @Type(() => ProtocolStep)
  public protocol_steps!: ProtocolStep[];

  @Type(() => ProtocolValue)
  public protocol_values!: ProtocolValue[];

  @Type(() => ProtocolTimer)
  public protocol_timers!: ProtocolTimer[];

  @Type(() => ProtocolTable)
  public protocol_tables!: ProtocolTable[];

  @Type(() => ExperimentWorkflow)
  public experiment_workflow!: ExperimentWorkflow;

  @Type(() => ProtocolDevice)
  public protocol_devices!: ProtocolDevice[];

  @Type(() => MetadataThread)
  public metadata_thread!: MetadataThread;

  @Type(() => Thread)
  public thread!: Thread;

  @Type(() => ModelFile)
  public files!: ModelFile[];

  @Type(() => PermaLink)
  public perma_link!: PermaLink;

  @Type(() => Molecule)
  public molecules!: Molecule[];

  public get hasEmptyState(): boolean {
    return this.state === null;
  }

  public get is_template(): boolean {
    return Boolean(!this);
  }

  public get canStart(): boolean {
    return !this.experiment_workflow_is_template;
  }

  public get isTemplate(): boolean {
    return this.experiment_workflow
      ? this.experiment_workflow.is_template
      : false;
  }

  public get hasAllStepsCompleted(): boolean {
    const stepsCompleted = this.protocol_steps.reduce(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (result: any, step: ProtocolStep) =>
        result + (step.ended_at ? 1 : 0),
      0,
    );
    if (stepsCompleted === this.protocol_steps.length) {
      return true;
    }
    return false;
  }

  public get hasAtLeastOneStepCompleted(): boolean {
    if (this.completeStepsCount > 0) {
      return true;
    }
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get stepsContentState(): any {
    if (this.state === null) {
      return [];
    }
    return this.state.content.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (node: any) =>
        node.type === 'experiment_step' ||
        node.type === 'protocol_step',
    );
  }

  /**
   * Re-order ProtocolSteps by Protocol $stepOrder attribute
   * @param  {object} experiment - Experiment Entity
   * @return {array} - ProtocolStep
   */
  public get protocolStepsOrdered(): ProtocolStep[] | ProtocolStep[] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.stepsContentState.map((node: any) =>
      this.protocol_steps.find(
        (protocolStep: ProtocolStep) =>
          protocolStep.guid === node.attrs.guid,
      ),
    );
  }

  public get protocolStepThreadIds(): number[] {
    return this.protocol_steps.reduce(
      (threadIds: number[], step) => [...threadIds, step.thread.id],
      [],
    );
  }

  public get completionPercentage(): string {
    return `${this.protocol_step_ended_count}/${this.protocol_step_count}`;
  }

  public static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }

  public get metadatas(): Metadata[] {
    return this.metadata_thread.metadatas;
  }

  public get protocolValuesNoVariable(): ProtocolValue[] {
    return this.protocol_values.filter((p) => !p.is_variable);
  }

  public get metadatasNoVariable(): Metadata[] {
    return this.metadatas.filter((m) => !m.is_variable);
  }

  public get canSetIsUseLatestProtocol(): boolean {
    return (
      !!this.protocol_collection_id &&
      this.experiment_workflow_is_template
    );
  }

  public get useLatestProtocol(): boolean {
    return (
      this.is_use_latest_protocol &&
      this.experiment_workflow_is_template &&
      !!this.protocol_collection_id
    );
  }

  public get completeStepsCount(): number {
    return this.protocol_steps.reduce(
      (result: number, step: ProtocolStep) =>
        result + (step.ended_at ? 1 : 0),
      0,
    );
  }

  public get incompleteStepsCount(): number {
    return this.protocol_steps.length - this.completeStepsCount;
  }

  public getProtocolValuesCountWithCondition(
    condition: ICondition,
  ): number {
    return this.protocolValuesNoVariable.reduce(
      (count: number, e) => count + (condition(e) ? 1 : 0),
      0,
    );
  }

  public get protocolValuesWithoutAmountCount(): number {
    return this.getProtocolValuesCountWithCondition((e) => !e.amount);
  }

  public get protocolValuesWithAmountNotDeductedCount(): number {
    return this.getProtocolValuesCountWithCondition(
      (e) => e.is_input && !e.amount_deducted_at,
    );
  }

  public get protocolValuesWithoutResourceItemOutputCount(): number {
    return this.getProtocolValuesCountWithCondition(
      (e) => e.is_output && !e.resource_item_output,
    );
  }

  public get metadataRequiredWithValueMissingCount(): number {
    return this.metadatasNoVariable.reduce(
      (count: number, m) =>
        count + (m.is_required && !m.hasValue ? 1 : 0),
      0,
    );
  }

  public get metadataNotRequiredWithValueMissingCount(): number {
    return this.metadatasNoVariable.reduce(
      (count: number, m) =>
        count + (!m.is_required && !m.hasValue ? 1 : 0),
      0,
    );
  }

  public get hasEmptyAllowedActionsLock(): boolean {
    return isEqual(
      this.allowed_actions_lock.sort(),
      Experiment.HARDCODED_ALLOWED_ACTIONS_LOCK.sort(),
    );
  }

  // static constant with hardcoded allowed actions
  private static readonly HARDCODED_ALLOWED_ACTIONS_LOCK = [
    'unlock',
    'experiment:unlock',
    'experiment:edit:decorations',
    'experiment:edit:allowed_actions_lock',
  ];
}
