/**
 * Labstep
 *
 * @module models/entity-view
 * @desc Typescript export class for Entity View
 */

import type { ColumnState } from 'ag-grid-community';
import { Type } from 'class-transformer';
import { Entity } from 'labstep-web/models/entity.model';
import { Group } from 'labstep-web/models/group.model';
import { Tag } from 'labstep-web/models/tag.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { IPostFilterParameters } from 'labstep-web/services/query-parameter.service';

export type EntityViewParameters = {
  template_guid?: string;
  resource_guid?: string;
  resource_template_guid?: string;
  device_template_guid?: string;
  protocol_collection_id?: string;
  context?: string;
  filter?: IPostFilterParameters['filter'];
};

export const defaultEntityViewColumnOptions = {
  default: {
    name: {
      showMetadataPreview: false,
    },
    resource_name: {
      showMetadataPreview: false,
    },
    status: {
      showLog: false,
    },
  },
  order_request_index: {
    name: {
      showMetadataPreview: true,
    },
    resource_name: {
      showMetadataPreview: true,
    },
    status: {
      showLog: true,
    },
  },
};

export type EntityViewColumnOptions = {
  name: {
    showMetadataPreview: boolean;
  };
  resource_name: {
    showMetadataPreview: boolean;
  };
  status: {
    showLog: boolean;
  };
};

export const entityViewColumnsColIds = Object.keys(
  defaultEntityViewColumnOptions.default,
);

export class EntityView extends Entity {
  public static readonly idAttr = 'guid' as const;

  public static readonly entityName = 'entity_view' as const;

  public get entityName(): string {
    return EntityView.entityName;
  }

  public constructor(data: Partial<EntityView> = {}) {
    super();
    Object.assign(this, data);
  }

  public is_template: boolean = false;

  public id!: never;

  public guid!: string;

  public name!: string;

  public entity_name!: string;

  public context!: string;

  public state?: ColumnState[] | null;

  public column_definition_ids?: string[] | null;

  public parameters?: EntityViewParameters | null;

  public column_options?: EntityViewColumnOptions | null;

  @Type(() => Group)
  group!: Group;

  @Type(() => Tag)
  tags!: Tag[];

  public static doesColIdHaveColumnOptions(colId: string) {
    return entityViewColumnsColIds.includes(colId);
  }

  public static getHumanReadableEntityName(
    plural?: boolean,
    capitalized?: boolean,
  ): string {
    return getHumanReadableEntityName(
      this.entityName,
      plural,
      capitalized,
    );
  }
}
