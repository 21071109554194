/**
 * Labstep
 *
 * @module grid/Report/coldefs
 * @desc Grid Report Column Definitions
 */

import { ValueGetterParams } from 'ag-grid-community';
import { ICONS } from 'labstep-web/constants/icons';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import Link from 'labstep-web/core/Link';
import { ReportingColDef } from 'labstep-web/grid/Report/coldefs/types';
import GridReportColumnHeader from 'labstep-web/grid/Report/components/ColumnHeader';
import { GridReportService } from 'labstep-web/grid/Report/services/grid-report.service';
import { Metadata } from 'labstep-web/models/metadata';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { humanReadableDate } from 'labstep-web/services/date.service';

export const experimentNameColDef: ReportingColDef = {
  colId: `experiment_name`,
  sortable: true,
  headerName: 'Experiment',
  headerComponentParams: {
    icon: ICONS.experiment_workflow.primary,
  },
  valueGetter: (params: ValueGetterParams) =>
    params.node?.data?.experiment?.experiment_workflow?.displayName,
  cellRenderer: (params: CellRendererParams<ProtocolCondition>) => {
    if (!params.node?.data?.experiment?.experiment_workflow) {
      return '';
    }
    return (
      <Link
        to="experiment_workflow_show"
        params={{
          id: params.node.data.experiment.experiment_workflow.guid,
        }}
        label={
          params.node.data.experiment.experiment_workflow.displayName
        }
      >
        {params.node.data.experiment.experiment_workflow.displayName}
      </Link>
    );
  },
};

export const experimentWorkflowCreatedAtColDef: ReportingColDef = {
  colId: `experiment_workflow_created_at`,
  sortable: true,
  headerName: 'Experiment Creation Date',
  headerComponentParams: {
    icon: ICONS.experiment_workflow.primary,
  },
  field: 'experiment.experiment_workflow.created_at',
  valueGetter: (params: ValueGetterParams) =>
    params.node?.data?.experiment?.experiment_workflow?.created_at,
  cellRenderer: (params: CellRendererParams<ProtocolCondition>) => {
    if (
      !params.node?.data?.experiment?.experiment_workflow?.created_at
    ) {
      return '';
    }
    return humanReadableDate(
      params.node.data.experiment.experiment_workflow.created_at,
    );
  },
};

export const experimentWorkflowStartedAtColDef: ReportingColDef = {
  colId: `experiment_workflow_started_at`,
  sortable: true,
  headerName: 'Experiment Start Date',
  headerComponentParams: {
    icon: 'calendar outline',
  },
  field: 'experiment.experiment_workflow.started_at',
  valueGetter: (params: ValueGetterParams) =>
    params.node?.data?.experiment?.experiment_workflow?.started_at,
  cellRenderer: (params: CellRendererParams<ProtocolCondition>) => {
    if (
      !params.node?.data?.experiment?.experiment_workflow?.started_at
    ) {
      return '';
    }
    return humanReadableDate(
      params.node.data.experiment.experiment_workflow.started_at,
    );
  },
};

export const experimentWorkflowEndedAtColDef: ReportingColDef = {
  colId: `experiment_workflow_ended_at`,
  sortable: true,
  headerName: 'Experiment Completion Date',
  headerComponentParams: {
    icon: 'calendar check outline',
  },
  field: 'experiment.experiment_workflow.ended_at',
  valueGetter: (params: ValueGetterParams) =>
    params.node?.data?.experiment?.experiment_workflow?.ended_at,
  cellRenderer: (params: CellRendererParams<ProtocolCondition>) => {
    if (
      !params.node?.data?.experiment?.experiment_workflow?.ended_at
    ) {
      return '';
    }
    return humanReadableDate(
      params.node.data.experiment.experiment_workflow.ended_at,
    );
  },
};

export const experimentStartedAtColDef: ReportingColDef = {
  colId: `experiment_started_at`,
  sortable: true,
  headerName: 'Protocol Start Date',
  headerComponentParams: {
    icon: 'calendar outline',
  },
  field: 'experiment.started_at',
  valueGetter: (params: ValueGetterParams) =>
    params.node?.data?.experiment?.started_at,
  cellRenderer: (params: CellRendererParams<ProtocolCondition>) => {
    if (!params.node?.data?.experiment?.started_at) {
      return '';
    }
    return humanReadableDate(params.node.data.experiment.started_at);
  },
};

export const experimentEndedAtColDef: ReportingColDef = {
  colId: `experiment_ended_at`,
  sortable: true,
  headerName: 'Protocol Completion Date',
  headerComponentParams: {
    icon: 'calendar check outline',
  },
  field: 'experiment.ended_at',
  valueGetter: (params: ValueGetterParams) =>
    params.node?.data?.experiment?.ended_at,
  cellRenderer: (params: CellRendererParams<ProtocolCondition>) => {
    if (!params.node?.data?.experiment?.ended_at) {
      return '';
    }
    return humanReadableDate(params.node.data.experiment.ended_at);
  },
};

export const experimentWorkflowEntityStateNameColDef: ReportingColDef =
  {
    colId: `experiment_workflow_entity_state_name`,
    sortable: true,
    headerName: 'Experiment Status',
    headerComponentParams: {
      icon: ICONS.entity_state_workflow.primary,
    },
    field: 'experiment.experiment_workflow.entity_state.name',
    valueGetter: (params: ValueGetterParams) =>
      params.node?.data?.experiment?.experiment_workflow?.entity_state
        ?.name,
    cellRenderer: (params: CellRendererParams<ProtocolCondition>) => {
      if (
        !params.node?.data?.experiment?.experiment_workflow
          ?.entity_state?.name
      ) {
        return '';
      }
      return params.node.data.experiment.experiment_workflow
        .entity_state.name;
    },
  };

export const protocolNameColDef: ReportingColDef = {
  colId: `protocol_name`,
  sortable: true,
  headerName: 'Protocol',
  headerComponentParams: {
    icon: ICONS.protocol_collection.primary,
  },
  valueGetter: (params: ValueGetterParams) =>
    params.node?.data?.experiment?.protocol?.collection?.name || '',
  cellRenderer: (params: CellRendererParams<ProtocolCondition>) => {
    if (!params.node?.data?.experiment?.protocol?.collection) {
      return '';
    }
    return (
      <Link
        to="protocol_collection_show"
        params={{
          id: params.node.data.experiment.protocol.collection.guid,
        }}
        label={params.node.data.experiment.protocol.collection.name}
      >
        {params.node.data.experiment.protocol.collection.name}
      </Link>
    );
  },
};

export interface PostFilterParameters {
  label: string;
  path: string;
  field: string;
}

export const COL_ID_DATE_MAPPING: Record<
  string,
  PostFilterParameters
> = {
  experiment_workflow_created_at: {
    label: 'Experiment Creation Date',
    path: 'experiment.experimentWorkflow',
    field: 'created_at',
  },
  experiment_workflow_started_at: {
    label: 'Experiment Start Date',
    path: 'experiment.experimentWorkflow',
    field: 'started_at',
  },
  experiment_workflow_ended_at: {
    label: 'Experiment Completion Date',
    path: 'experiment.experimentWorkflow',
    field: 'ended_at',
  },
  experiment_started_at: {
    label: 'Protocol Start Date',
    path: 'experiment',
    field: 'started_at',
  },
  experiment_ended_at: {
    label: 'Protocol Completion Date',
    path: 'experiment',
    field: 'ended_at',
  },
};

export const COL_ID_MAPPING: Record<string, ReportingColDef> = {
  experiment_name: experimentNameColDef,
  experiment_workflow_created_at: experimentWorkflowCreatedAtColDef,
  experiment_workflow_started_at: experimentWorkflowStartedAtColDef,
  experiment_workflow_ended_at: experimentWorkflowEndedAtColDef,
  experiment_workflow_entity_state_name:
    experimentWorkflowEntityStateNameColDef,
  protocol_name: protocolNameColDef,
  experiment_started_at: experimentStartedAtColDef,
  experiment_ended_at: experimentEndedAtColDef,
};

export const getMetadataExperimentColDef = (
  type: MetadataType,
  metadataLabel: string,
): ReportingColDef => {
  return {
    colId: `metadata:experiment:${type}:${metadataLabel}`,
    cellRendererParams: {
      metadata: new Metadata({
        type,
        label: metadataLabel,
      }),
      postFilterPath: 'metadatas',
    },
    sortable: true,
    headerName: metadataLabel,
    headerComponent: GridReportColumnHeader,
    headerComponentParams: {
      icon: ICONS.metadata.primary,
    },
    valueGetter: (params: ValueGetterParams) =>
      !params.node
        ? ''
        : GridReportService.metadataCellRenderer(
            [
              ...params.node.data.metadatas_constant,
              ...params.node.data.metadatas_variable,
            ],
            metadataLabel,
          ),
    cellRenderer: (params: CellRendererParams<ProtocolCondition>) =>
      GridReportService.metadataCellRenderer(
        [
          ...params.node.data.metadatas_constant,
          ...params.node.data.metadatas_variable,
        ],
        metadataLabel,
      ),
  };
};

export const getMetadataResourceItemColDef = (
  protocolValueName: string,
  type: MetadataType,
  metadataLabel: string,
) => ({
  colId: `metadata:resource_item:${type}:${metadataLabel}:${protocolValueName}`,
  cellRendererParams: {
    metadata: new Metadata({
      type,
      label: metadataLabel,
    }),
    postFilterPath: 'protocolValues.resourceItem.metadatas',
  },
  sortable: true,
  headerName: metadataLabel,
  headerComponentParams: {
    icon: ICONS.metadata.secondary,
    cornerIcon: ICONS.resource_item.primary,
    secondaryHeader: `Item - ${protocolValueName}`,
  },
  valueGetter: (params: ValueGetterParams) => {
    if (!params.node) {
      return '';
    }
    const foundProtocolValue = GridReportService.getProtocolValue(
      [
        ...params.node.data.protocol_values_constant,
        ...params.node.data.protocol_values_variable,
      ],
      protocolValueName,
    );
    if (!foundProtocolValue || !foundProtocolValue.resourceItem) {
      return '';
    }
    return GridReportService.metadataCellRenderer(
      foundProtocolValue.resourceItem!.metadata_thread.metadatas,
      metadataLabel,
    );
  },
  cellRenderer: (params: CellRendererParams<ProtocolCondition>) => {
    const foundProtocolValue = GridReportService.getProtocolValue(
      [
        ...params.node.data.protocol_values_constant,
        ...params.node.data.protocol_values_variable,
      ],
      protocolValueName,
    );
    if (!foundProtocolValue || !foundProtocolValue.resourceItem) {
      return '';
    }
    return GridReportService.metadataCellRenderer(
      foundProtocolValue.resourceItem!.metadata_thread.metadatas,
      metadataLabel,
    );
  },
});

export const getMetadataResourceColDef = (
  protocolValueName: string,
  type: MetadataType,
  metadataLabel: string,
) => ({
  colId: `metadata:resource:${type}:${metadataLabel}:${protocolValueName}`,
  cellRendererParams: {
    metadata: new Metadata({
      type,
      label: metadataLabel,
    }),
    postFilterPath: 'protocolValues.resourceItem.resource.metadatas',
  },
  sortable: true,
  headerName: metadataLabel,
  headerComponentParams: {
    icon: ICONS.metadata.secondary,
    cornerIcon: ICONS.resource.primary,
    secondaryHeader: `Resource - ${protocolValueName}`,
  },
  valueGetter: (params: ValueGetterParams) => {
    if (!params.node) {
      return '';
    }
    const foundProtocolValue = GridReportService.getProtocolValue(
      [
        ...params.node.data.protocol_values_constant,
        ...params.node.data.protocol_values_variable,
      ],
      protocolValueName,
    );
    if (!foundProtocolValue || !foundProtocolValue.resourceItem) {
      return '';
    }
    return GridReportService.metadataCellRenderer(
      foundProtocolValue.resourceItem!.resource.metadata_thread
        .metadatas,
      metadataLabel,
    );
  },
  cellRenderer: (params: CellRendererParams<ProtocolCondition>) => {
    const foundProtocolValue = GridReportService.getProtocolValue(
      [
        ...params.node.data.protocol_values_constant,
        ...params.node.data.protocol_values_variable,
      ],
      protocolValueName,
    );
    if (!foundProtocolValue || !foundProtocolValue.resourceItem) {
      return '';
    }
    return GridReportService.metadataCellRenderer(
      foundProtocolValue.resourceItem!.resource.metadata_thread
        .metadatas,
      metadataLabel,
    );
  },
});

export const getProtocolValueFieldColumnDef = (
  isInput: boolean,
  name: string,
  field: string,
): ReportingColDef => ({
  colId: `protocol_value:${
    isInput ? 'true' : 'false'
  }:${name}:${field}`,
  sortable: true,
  headerName: 'Amount',
  headerComponentParams: {
    icon: ICONS.protocol_value.info.amount_used,
    secondaryHeader: name,
  },
  valueGetter: (params: ValueGetterParams) =>
    !params.node
      ? ''
      : GridReportService.protocolValueCellRenderer(
          [
            ...params.node.data.protocol_values_constant,
            ...params.node.data.protocol_values_variable,
          ],
          name,
          field,
        ),
  cellRenderer: (params: CellRendererParams<ProtocolCondition>) =>
    GridReportService.protocolValueCellRenderer(
      [
        ...params.node.data.protocol_values_constant,
        ...params.node.data.protocol_values_variable,
      ],
      name,
      field,
    ),
});

export const getProtocolValueResourceNameColumnDef = (
  isInput: boolean,
  name: string,
): ReportingColDef => ({
  colId: `protocol_value:${
    isInput ? 'true' : 'false'
  }:${name}:resource_name`,
  sortable: true,
  headerName: 'Resource',
  headerComponentParams: {
    icon: ICONS.resource.primary,
    secondaryHeader: name,
  },
  valueGetter: (params: ValueGetterParams) => {
    if (!params.node) {
      return '';
    }
    const foundProtocolValue = GridReportService.getProtocolValue(
      [
        ...params.node.data.protocol_values_constant,
        ...params.node.data.protocol_values_variable,
      ],
      name,
    );
    if (!foundProtocolValue || !foundProtocolValue.resourceItem) {
      return '';
    }
    return foundProtocolValue.resourceItem.resource.name;
  },
  cellRenderer: (params: CellRendererParams<ProtocolCondition>) => {
    const foundProtocolValue = GridReportService.getProtocolValue(
      [
        ...params.node.data.protocol_values_constant,
        ...params.node.data.protocol_values_variable,
      ],
      name,
    );
    if (
      !foundProtocolValue ||
      !foundProtocolValue.resourceItem?.resource
    ) {
      return '';
    }
    return (
      <Link
        to="resource_show"
        params={{
          id: foundProtocolValue.resourceItem?.resource.guid,
        }}
      >
        {foundProtocolValue.resourceItem.resource.name}
      </Link>
    );
  },
});

export const getProtocolValueResourceItemNameColumnDef = (
  isInput: boolean,
  name: string,
): ReportingColDef => ({
  colId: `protocol_value:${
    isInput ? 'true' : 'false'
  }:${name}:resource_item_name`,
  sortable: true,
  headerName: 'Item',
  headerComponentParams: {
    icon: ICONS.resource_item.primary,
    secondaryHeader: name,
  },
  valueGetter: (params: ValueGetterParams) => {
    if (!params.node) {
      return '';
    }
    const foundProtocolValue = GridReportService.getProtocolValue(
      [
        ...params.node.data.protocol_values_constant,
        ...params.node.data.protocol_values_variable,
      ],
      name,
    );
    return foundProtocolValue?.resourceItem?.name || '';
  },
  cellRenderer: (params: CellRendererParams<ProtocolCondition>) => {
    const foundProtocolValue = GridReportService.getProtocolValue(
      [
        ...params.node.data.protocol_values_constant,
        ...params.node.data.protocol_values_variable,
      ],
      name,
    );
    if (!foundProtocolValue || !foundProtocolValue.resourceItem) {
      return '';
    }
    return (
      <Link
        to="resource_item_show"
        params={{
          id: foundProtocolValue.resourceItem.guid,
        }}
        label={foundProtocolValue.resourceItem.name || ''}
      >
        {foundProtocolValue.resourceItem.name}
      </Link>
    );
  },
});

export const getProtocolValueResourceLocationPathColumnDef = (
  isInput: boolean,
  name: string,
): ReportingColDef => ({
  colId: `protocol_value:${
    isInput ? 'true' : 'false'
  }:${name}:resource_location_path`,
  sortable: true,
  headerName: 'Location',
  headerComponentParams: {
    icon: ICONS.resource_location.primary,
    secondaryHeader: name,
  },
  valueGetter: (params: ValueGetterParams) => {
    if (!params.node) {
      return '';
    }
    const foundProtocolValue = GridReportService.getProtocolValue(
      [
        ...params.node.data.protocol_values_constant,
        ...params.node.data.protocol_values_variable,
      ],
      name,
    );
    return foundProtocolValue?.resourceItem?.resource_location
      ?.location_path;
  },
  cellRenderer: (params: CellRendererParams<ProtocolCondition>) => {
    const foundProtocolValue = GridReportService.getProtocolValue(
      [
        ...params.node.data.protocol_values_constant,
        ...params.node.data.protocol_values_variable,
      ],
      name,
    );
    if (!foundProtocolValue?.resourceItem?.resource_location) {
      return '';
    }
    return (
      <Link
        to="resource_location_show"
        params={{
          id: foundProtocolValue.resourceItem.resource_location.guid,
        }}
        label={
          foundProtocolValue.resourceItem.resource_location
            .location_path || ''
        }
      >
        {
          foundProtocolValue.resourceItem.resource_location
            .location_path
        }
      </Link>
    );
  },
});
