/**
 * Labstep
 *
 * @module components/Log/Breadcrumb
 * @desc Breadcrumb for Log
 */

import {
  ICONS,
  IEntityIconsProps,
} from 'labstep-web/constants/icons';
import Breadcrumb from 'labstep-web/core/Breadcrumb';
import { IBreadcrumbLink } from 'labstep-web/core/Breadcrumb/types';
import { Metadata } from 'labstep-web/models/metadata';
import { cloneDeep } from 'lodash';
import React from 'react';
import { LogBreadcrumbProps } from './types';

export const LogBreadcrumb: React.FC<LogBreadcrumbProps> = ({
  log,
  entityName,
}) => {
  const memoizedLinks = React.useMemo(() => {
    const links: IBreadcrumbLink[] = [];

    if (log.loggable_parents) {
      const loggableParents = cloneDeep(log.loggable_parents);

      const parentClasses = loggableParents.map((p) => p.entity_name);

      for (const loggableEntity of loggableParents.reverse()) {
        if (loggableEntity.entity_name === entityName) {
          break;
        }

        const link = {
          icon: ICONS[
            loggableEntity.entity_name as keyof typeof ICONS
          ]?.primary,
          name: loggableEntity.name,
          // FIXME route: log.entityLevelFive.route,
        } as any;

        if (loggableEntity.is_template) {
          link.icon = (
            ICONS[
              loggableEntity.entity_name as keyof typeof ICONS
            ] as IEntityIconsProps
          )?.template;
        }

        if (loggableEntity.is_root) {
          continue;
        }

        const parentClasses = loggableParents.map(
          (p) => p.entity_name,
        );

        if (
          loggableEntity.entity_name === Metadata.entityName &&
          !parentClasses.includes('experiment') &&
          !parentClasses.includes('protocol')
        ) {
          link.icon = ICONS[Metadata.entityName].secondary;
        }

        links.push(link);
      }
    }

    return links.reverse();
  }, [log.loggable_parents, entityName]);

  return <Breadcrumb links={memoizedLinks} />;
};

export default LogBreadcrumb;
