/**
 * Labstep
 *
 * @module components/App/Routes
 * @desc Main App routes
 */

import FileUploadToast from 'labstep-web/components/File/UploadToast';
import FullPageError from 'labstep-web/components/Layout/FullPageError';
import FullScreenContainer from 'labstep-web/components/Layout/FullScreen';
import { subLinks } from 'labstep-web/components/Layout/FullScreen/Links/constants';
import { withAuthenticatedUser } from 'labstep-web/containers/AuthenticatedUser';
import { ErrorBoundaryContainer } from 'labstep-web/containers/ErrorBoundary';
import Location from 'labstep-web/core/Location';
import Route from 'labstep-web/core/Route';
import ScrollTracker from 'labstep-web/core/ScrollTracker';
import AuthenticationScreen from 'labstep-web/screens/Authentication';
import LogoutJupyter from 'labstep-web/screens/Authentication/LogoutJupyter';
import DeviceShow from 'labstep-web/screens/Device/Show';
import EntityStateWorkflowShow from 'labstep-web/screens/EntityStateWorkflow/Show';
import EntityViewShow from 'labstep-web/screens/EntityView/Show';
import NoRouteMatch from 'labstep-web/screens/Error/NoRouteMatch';
import UnhandledError from 'labstep-web/screens/Error/UnhandledError';
import ExperimentPrint from 'labstep-web/screens/Experiment/Print';
import ExperimentWorkflowPrint from 'labstep-web/screens/ExperimentWorkflow/Print';
import GroupCreate from 'labstep-web/screens/Group/Create';
import ScreensGroupIndex from 'labstep-web/screens/Group/Index';
import GroupShow from 'labstep-web/screens/Group/Show';
import JupyterAuthorize from 'labstep-web/screens/Jupyter/Authorize';
import NotificationIndex from 'labstep-web/screens/Notification/Index';
import ScreensNotificationRedirect from 'labstep-web/screens/Notification/Redirect';
import ScreensOAuthBox from 'labstep-web/screens/OAuth/Box';
import ScreensOnboarding from 'labstep-web/screens/Onboarding';
import ScreensOrderRequestShow from 'labstep-web/screens/OrderRequest/Show';
import ScreensOrganizationShow from 'labstep-web/screens/Organization/Show';
import PermaLinkShowScreen from 'labstep-web/screens/PermaLink/Show';
import ProtocolPrint from 'labstep-web/screens/Protocol/Print';
import ProtocolShow from 'labstep-web/screens/Protocol/Show';
import ProtocolCollectionShow from 'labstep-web/screens/ProtocolCollection/Show';
import PurchaseOrderPrint from 'labstep-web/screens/PurchaseOrder/Print';
import ScreensPurchaseOrderShow from 'labstep-web/screens/PurchaseOrder/Show';
import ResourceShow from 'labstep-web/screens/Resource/Show';
import ScreensResourceItemShow from 'labstep-web/screens/ResourceItem/Show';
import ScreensResourceLocationShow from 'labstep-web/screens/ResourceLocation/Show';
import ScreensResourceTemplateShow from 'labstep-web/screens/ResourceTemplate/Show';
import ShareLinkShowScreen from 'labstep-web/screens/ShareLink/Show';
import VerifyEmail from 'labstep-web/screens/VerifyEmail';
import { navigation } from 'labstep-web/services/navigation';
import SetupAuthenticated from 'labstep-web/tests/SetupAuthenticated';
import React from 'react';
import {
  Route as ReactRouterDomRoute,
  Redirect,
  Switch,
} from 'react-router-dom';
import Modals from './Modals';
import Redirection from './Redirection';
import AppRoutesTransitionExperimentWorkflowExperiment from './Transition/ExperimentWorkflowExperiment';
import {
  MainScreenRouteDefinition,
  RouteDefinition,
  RoutesContainerProps,
} from './types';

// Routes with breadcrumbs
const mainScreenRoutes: MainScreenRouteDefinition[] = [
  {
    id: 'redirection',
    path: [
      'overview',
      'tag_index',
      'protocol_collection_index',
      'entity_view_index',
      'experiment_workflow_index',
      'experiment_workflow_template_index',
      'order_request_index',
      'purchase_order_index',
      'device_index',
      'resource_template_index',
      'device_template_index',
      'resource_index',
      'resource_item_index',
      'file_index',
      'resource_location_index',
      'user_index',
      'settings',
      'settings_notifications',
      'settings_order_info',
    ],
    component: Redirection,
    breadcrumb: {},
  },
  // EntityStateWorkflow
  {
    id: 'entity_state_workflow_show',
    path: [
      'entity_state_workflow_show',
      'entity_state_workflow_show_experiment_workflow_template',
      'entity_state_workflow_show_experiment_workflow',
      'entity_state_workflow_show_activity',
    ],
    component: EntityStateWorkflowShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.experiments,
    },
  },
  // OrderRequest
  {
    id: 'order_request_show',
    path: [
      'order_request_show',
      'order_request_show_thread',
      'order_request_show_resource_items',
      'order_request_show_activity',
      'order_request_show_metadata',
    ],
    component: ScreensOrderRequestShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.ordering,
    },
  },
  // PurchaseOrder
  {
    id: 'purchase_order_show',
    path: [
      'purchase_order_show',
      'purchase_order_show_thread',
      'purchase_order_show_metadata',
      'purchase_order_show_activity',
    ],
    component: ScreensPurchaseOrderShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.ordering,
    },
  },
  // ResourceTemplate
  {
    id: 'resource_template_show',
    path: [
      'resource_template_show',
      'resource_template_show_thread',
      'resource_template_show_activity',
      'resource_template_show_resource_item_template',
      'resource_template_show_imports',
      'resource_template_show_metadata',
    ],
    component: ScreensResourceTemplateShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.inventory,
    },
  },
  // ResourceLocation
  {
    id: 'resource_location_show',
    path: [
      'resource_location_show',
      'resource_location_show_sublocations',
      'resource_location_show_metadata',
      'resource_location_show_resource_items',
      'resource_location_show_thread',
      'resource_location_show_activity',
    ],
    component: ScreensResourceLocationShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.inventory,
    },
  },
  // ResourceItem
  {
    id: 'resource_item_show',
    path: [
      'resource_item_show',
      'resource_item_show_thread',
      'resource_item_show_activity',
      'resource_item_show_lineage',
      'resource_item_show_experiment_workflows',
      'resource_item_show_metadata',
      'resource_item_show_permalink',
    ],
    component: ScreensResourceItemShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.inventory,
    },
  },
  // Group
  {
    id: 'group_show',
    path: ['group_show', 'group_overview', 'group_thread'],
    component: GroupShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.overview,
    },
  },
  // Group Experiments
  {
    id: 'group_experiment_workflows',
    path: [
      'group_experiment_workflows',
      'group_experiment_workflow_templates',
    ],
    component: GroupShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.experiments,
    },
  },
  // Group Settings
  {
    id: 'group_settings',
    path: [
      'group_settings',
      'group_users',
      'group_settings_experiment_workflow',
      'group_settings_experiment_workflow_templates',
      'group_settings_experiment_entity_state_workflow',
      'group_settings_experiment_tags',
      'group_settings_protocol_tags',
      'group_settings_inventory_categories',
      'group_settings_inventory_locations',
      'group_settings_inventory_tags',
      'group_settings_device_categories',
      'group_settings_device_tags',
      'group_settings_order_request_template',
      'group_settings_purchase_order_template',
      'group_settings_order_request_tags',
      'group_settings_export',
      'group_settings_auto_sharing',
      'group_settings_notifications',
    ],
    component: GroupShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.settings,
    },
  },
  // Group Inventory
  {
    id: 'group_resources',
    path: ['group_resources', 'group_resource_items'],
    component: GroupShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.inventory,
    },
  },
  // Group Categories
  {
    id: 'group_resource_categories',
    path: ['group_resource_categories'],
    component: GroupShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.inventory,
    },
  },
  {
    id: 'group_device_categories',
    path: ['group_device_categories'],
    component: GroupShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.inventory,
    },
  },
  // Group Protocols
  {
    id: 'group_protocol_collections',
    path: ['group_protocol_collections'],
    component: GroupShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.protocols,
    },
  },
  // Group Ordering
  {
    id: 'group_order_requests',
    path: ['group_order_requests', 'group_purchase_orders'],
    component: GroupShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.ordering,
    },
  },
  // Group Devices
  {
    id: 'group_devices',
    path: ['group_devices'],
    component: GroupShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.devices,
    },
  },
  // Group ProtocolCondition
  {
    id: 'group_entity_views',
    path: ['group_entity_views'],
    component: GroupShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.entity_views,
    },
  },
  // Group Files
  {
    id: 'group_files',
    path: ['group_files'],
    component: GroupShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.files,
    },
  },

  // Protocol
  {
    id: 'protocol_show',
    path: 'protocol_show',
    component: ProtocolShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.protocols,
    },
    isExact: false,
  },
  // Protocol Collection
  {
    id: 'protocol_collection_show',
    path: 'protocol_collection_show',
    component: ProtocolCollectionShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.protocols,
    },
    isExact: false,
  },
  // PermaLink
  {
    id: 'permalink_show',
    path: 'permalink_show',
    component: PermaLinkShowScreen,
    breadcrumb: {},
  },
  // Device
  {
    id: 'device_show',
    path: [
      'device_show',
      'device_show_permalink',
      'device_show_metadata',
      'device_show_device_data',
      'device_show_thread',
      'device_show_activity',
      'device_show_experiment_workflows',
      'device_show_protocols',
    ],
    component: DeviceShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.devices,
    },
  },
  // Resource
  {
    id: 'resource_show',
    path: [
      'resource_show',
      'resource_show_permalink',
      'resource_show_order_requests',
      'resource_show_metadata',
      'resource_show_resource_items',
      'resource_show_resource_item_template',
      'resource_show_experiment_workflows',
      'resource_show_thread',
      'resource_show_activity',
    ],
    component: ResourceShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.inventory,
    },
  },
  // EntityView
  {
    id: 'entity_view_show',
    path: ['entity_view_show'],
    component: EntityViewShow,
    breadcrumb: {
      organization: true,
      group: true,
      module: subLinks.entity_views,
    },
  },
  // Notification
  {
    id: 'notification_index',
    path: 'notification_index',
    component: NotificationIndex,
    breadcrumb: {
      title: { icon: 'bell', text: 'Notifications' },
    },
  },
  {
    id: 'unhandled_error',
    path: 'unhandled_error',
    component: UnhandledError,
    breadcrumb: {},
  },
  {
    id: 'notification_redirect',
    path: 'notification_redirect',
    component: ScreensNotificationRedirect,
    breadcrumb: {},
  },
  // Organization
  {
    id: 'organization_show',
    path: [
      'organization_show',
      'organization_show_billing',
      'organization_show_permissions',
      'organization_show_users',
      'organization_show_log',
      'organization_show_activity',
      'organization_show_security',
      'organization_show_export',
      'organization_show_sso',
      'organization_show_settings',
    ],
    component: ScreensOrganizationShow,
    breadcrumb: { organization: true },
  },
  // Group Index
  {
    id: 'group_index',
    path: ['group_index', 'group_index_organization'],
    component: ScreensGroupIndex,
    breadcrumb: {
      title: { icon: 'sitemap', text: 'All Workspaces' },
    },
  },
  // Group
  {
    id: 'group_create',
    path: 'group_create',
    component: GroupCreate,
    breadcrumb: { organization: true },
  },
];

export const MainScreen: React.FC = () => (
  <Switch>
    {mainScreenRoutes.map((route) => (
      <ReactRouterDomRoute
        key={route.id}
        exact={route.isExact !== false}
        path={
          Array.isArray(route.path)
            ? route.path.map((p) => navigation.get(p))
            : navigation.get(route.path)
        }
        render={(props): React.ReactNode => {
          const Component = route.component;

          return (
            <FullScreenContainer breadcrumb={route.breadcrumb}>
              <Component {...props} />
            </FullScreenContainer>
          );
        }}
      />
    ))}
    <ReactRouterDomRoute
      render={(): React.ReactNode => (
        <NoRouteMatch to="app_homepage" />
      )}
    />
  </Switch>
);

// Fullscreen routes
const routes: RouteDefinition[] = [
  {
    id: 'authentication',
    path: [
      'forgot_password',
      'reset_password',
      'set_password',
      'signup',
      'signup_enterprise',
      'login',
      'login_saml',
      'saml_get_login_url',
      'saml_login',
      'saml_login_new',
    ],
    component: AuthenticationScreen,
    publicPath: true,
  },
  {
    id: 'logout_jupyter',
    path: ['logout_jupyter'],
    component: LogoutJupyter,
    publicPath: true,
  },
  {
    id: 'experiment_workflow_print',
    path: 'experiment_workflow_print',
    component: ExperimentWorkflowPrint,
  },
  {
    id: 'experiment_print',
    path: 'experiment_print',
    component: ExperimentPrint,
  },
  {
    id: 'experiment_workflow_show',
    path: ['experiment_workflow_show', 'experiment_show'],
    component: AppRoutesTransitionExperimentWorkflowExperiment,
    isExact: false,
  },
  {
    id: 'purchase_order_print',
    path: 'purchase_order_print',
    component: PurchaseOrderPrint,
  },
  {
    id: 'protocol_print',
    path: 'protocol_print',
    component: ProtocolPrint,
  },
  {
    id: 'sharelink_show',
    path: 'sharelink_show',
    component: ShareLinkShowScreen,
    publicPath: true,
  },
  {
    id: 'onboarding',
    path: [
      'onboarding_create_workspace',
      'onboarding_invite',
      'onboarding_overview',
    ],
    component: ScreensOnboarding,
  },
  {
    id: 'jupyter_authorize',
    path: 'jupyter_authorize',
    component: JupyterAuthorize,
  },
  {
    id: 'verify_email',
    path: 'verify_email',
    component: VerifyEmail,
    publicPath: true,
  },
  {
    id: 'oauth_box',
    path: 'oauth_box',
    component: ScreensOAuthBox,
    publicPath: true,
  },
  ...mainScreenRoutes.map((route) => ({
    ...route,
    component: MainScreen,
  })),
  {
    id: 'setup_e2e',
    path: ['setup_e2e'],
    component: SetupAuthenticated,
    publicPath: true,
  },
];

export const RoutesContainer: React.FC<RoutesContainerProps> = ({
  authenticatedUser,
}) => (
  <ErrorBoundaryContainer
    FallbackComponent={<FullPageError showSignout />}
  >
    <Switch>
      {routes.map((route: RouteDefinition) => (
        <Route
          key={route.id}
          publicPath={route.publicPath}
          exact={route.isExact !== false}
          path={
            Array.isArray(route.path)
              ? route.path.map((p) => navigation.get(p))
              : navigation.get(route.path)
          }
          component={route.component}
        />
      ))}
      <Redirect
        exact
        from={navigation.get('app_homepage')}
        to={navigation.get(
          authenticatedUser.authenticated ? 'overview' : 'login',
        )}
      />
      <ReactRouterDomRoute
        render={(): React.ReactNode => <MainScreen />}
      />
    </Switch>
    <Location />
    <ErrorBoundaryContainer>
      <ScrollTracker />
    </ErrorBoundaryContainer>
    <FileUploadToast />
    <Modals />
  </ErrorBoundaryContainer>
);

export default withAuthenticatedUser(RoutesContainer);
